<template>
	<div class="page">
		<div class="content">
			<img src="../../assets/account/login.png" class="left" alt="">
			<div class="right">
				<el-tabs v-model="activeName" :stretch="true">
					<el-tab-pane label="登录" name="login">
						<el-form style="margin-top: 76px;" :model="form" :rules="rules" status-icon ref="loginForm">
							<el-form-item prop="account">
								<el-input clearable v-model="form.account" placeholder="手机号" autocomplete="off">
								</el-input>
							</el-form-item>
							<el-form-item prop="password">
								<el-input clearable show-password v-model="form.password" placeholder="密码">
								</el-input>
							</el-form-item>
							<el-form-item>
								<el-button style="width: 100%;margin-top: 90px;" type="primary"
									@click="login('loginForm')">登录
								</el-button>
							</el-form-item>
						</el-form>
					</el-tab-pane>
					<el-tab-pane label="注册" name="reg">
						<el-form style="margin-top: 76px;" :model="form" :rules="rules" status-icon ref="regForm">
							<el-form-item prop="account">
								<el-input clearable v-model="form.account" placeholder="手机号" autocomplete="off">
								</el-input>
							</el-form-item>
							<el-form-item prop="password">
								<el-input clearable show-password v-model="form.password" placeholder="密码">
								</el-input>
							</el-form-item>
							<el-form-item prop="checkPassword">
								<el-input clearable show-password v-model="form.checkPassword" placeholder="确认密码">
								</el-input>
							</el-form-item>
							<el-form-item>
								<el-button style="width: 100%;margin-top: 90px;" type="primary" @click="reg('regForm')">
									注册
								</el-button>
							</el-form-item>
						</el-form>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: 'login',
				rules: {
					account: [{
						required: true,
						message: '账号不能为空'
					}, ],
					password: [{
						required: true,
						message: '密码不能为空'
					}, {
						min: 5,
						message: '长度至少 6 个字符',
						trigger: 'blur'
					}],
					checkPassword: [{
							required: true,
							message: '请再次输入密码'
						},
						{
							validator: (rule, value, callback) => {
								if (value !== this.form.password) {
									callback(new Error('两次输入密码不一致!'));
								} else {
									callback();
								}
							},
							trigger: 'blur'
						}
					]
				},
				form: {
					password: '',
					checkPassword: '',
					account: ''
				},
			}
		},
		methods: {
			login(formName) {
				this.$refs[formName].validate(async valid => {
					if (!valid) {
						return false;
					} else {
						let {
							token
						} = await this.$api.userLogin(this.form)
						this.$ok('登录成功')
						localStorage.setItem('token', token)
						this.$go('/')
					}
				});
			},
			reg(formName) {
				this.$refs[formName].validate(async valid => {
					if (!valid) {
						return false;
					} else {
						await this.$api.userRegister(this.form)
						this.$ok('注册成功')
						this.activeName = 'login'
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		background-image: url(../../assets/account/bg.png);
		background-size: 100vw 980px;
		height: 980px;

		.content {
			margin: 159px auto;
			width: 1200px;
			height: 655px;
			display: flex;

			.left {
				flex: 1;
			}

			.right {
				padding: 50px 100px;
				flex: 1;
				background-color: #fff;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
		}
	}
</style>
